/** @jsxImportSource @emotion/react */

import { Image } from "antd";
import { GameApi } from "api/game";
import { SubGameResponse } from "api/game/type";
import iPlayLogo from "assets/Common/icons/iplay_logo 2.png";
import Loading from "components/Common/Loading";
import { headerMenu, HeaderMenuStyle } from "contants/headerMenu";
import { CategoryGameType, LIST_CATEGORY_GAME } from "contants/menu-game";
import { PATH } from "contants/path";
import { GameTypeEnum } from "enums/game";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getAnnouncement } from "store/announcement/announcement.thunk";
import { selectIsAuthenticated, selectUsername } from "store/auth";
import { updateGameProviderCode } from "store/game";
import { getGameList } from "store/game/game.thunk";
import { ModalType, setOpenModal } from "store/modal";
import { selectProfileLoading } from "store/user";
import { theme } from "styles/theme";

import ButtonTelegram from "./ButtonTelegram";
import DropdownGameProvider from "./DropdownGameProvider";
import HeaderAuth from "./HeaderAuth";
import HeaderUnAuth from "./HeaderUnAuth";
import { HeaderStyle } from "./index.style";
import LanguageSelect from "./LanguageSelect";

const isShow = (isAuthenticated: boolean, name: string) => {
  if (
    name.includes("Promos") ||
    name.includes("Download") ||
    name.includes("AFFRegister") ||
    name.includes("Affiliate")
  )
    return true;

  if (isAuthenticated) return true;

  return false;
};

export const Header = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { pathname = "" } = useLocation();
  const { t } = useTranslation(["header", "currency"]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isLoading = useAppSelector(selectProfileLoading);
  const username = useAppSelector(selectUsername);

  const [visibleMenu, setVisibleMenu] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<
    "white" | "transparent"
  >("transparent");
  const [colorText, setColorText] = useState<string>("white");

  const handleChangeColor = () => {
    if (
      (!pathname.includes("user") &&
        !pathname.includes("terms-and-conditions")) ||
      pathname === "/"
    ) {
      setColorText(`${theme.colors.lightText}`);
    } else {
      setColorText("white");
    }
  };

  const handleScroll = () => {
    if (window.scrollY < 15) {
      setBackgroundColor("transparent");
      handleChangeColor();
      return;
    }
    setColorText(`${theme.colors.lightText}`);
    setBackgroundColor("white");
  };

  const handleActionMenu = (item: HeaderMenuStyle) => {
    if (
      item.link === PATH.Promotion ||
      item.link === PATH.User.Referral ||
      item.link === PATH.Download ||
      item.link === PATH.User.myVip ||
      item.link === PATH.affiliatePage
    ) {
      if (item.link === PATH.affiliatePage) {
        window.open(PATH.affiliatePage);
      } else {
        navigate(item.link);
      }
      return;
    }
    dispatch(setOpenModal(item.link as ModalType));
  };

  const getGameLobby = async (
    idGame: string,
    path: string,
    providerCode?: string
  ) => {
    const res = await dispatch(getGameList(idGame));

    const { data } = res.payload as SubGameResponse;

    if (data.length > 0) {
      dispatch(
        updateGameProviderCode(
          providerCode ? providerCode : data[0].provider_code
        )
      );
      navigate(
        `${path}/${providerCode ? providerCode : data[0].provider_code}`
      );
    }
  };

  const handleRedirectSubGame = (
    idGame: string,
    path: string,
    providerCode?: string
  ) => {
    if (
      idGame.includes(GameTypeEnum.Slot) ||
      idGame.includes(GameTypeEnum.Fishing) ||
      idGame.includes(GameTypeEnum.BoardGame)
    ) {
      setVisibleMenu("");
      return getGameLobby(idGame, path, providerCode);
    }

    navigate(path);
  };

  useEffect(() => {
    if (!username) return;
    dispatch(getAnnouncement({ page: 1, size: 100, type: 2 }));
  }, [username]);

  useEffect(() => {
    handleChangeColor();
    setBackgroundColor("transparent");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event?.target as Node;
      if (!menuRef?.current?.contains(target)) {
        setVisibleMenu("");
      }
    };
    if (visibleMenu) {
      document.addEventListener("mouseover", handleClickOutside);
    } else {
      document.removeEventListener("mouseover", handleClickOutside);
    }
    return () => document.removeEventListener("mouseover", handleClickOutside);
  }, [visibleMenu]);

  return (
    <div ref={menuRef} css={HeaderStyle.index(backgroundColor)}>
      <div css={HeaderStyle.container}>
        <div css={HeaderStyle.wrapper}>
          <div css={HeaderStyle.heading} onClick={() => navigate(PATH.Home)}>
            <Image alt="img" src={iPlayLogo} preview={false} />
          </div>

          <div css={HeaderStyle.listCategory}>
            {LIST_CATEGORY_GAME.map((item: CategoryGameType, index: number) => (
              <div key={item.id}>
                <div
                  css={HeaderStyle.categoryItem(item, pathname, colorText)}
                  onClick={() => {
                    handleRedirectSubGame(item.id, item.path);
                  }}
                  onMouseEnter={(e) => {
                    if (item.id !== "H") {
                      setVisibleMenu(item.id);
                    } else {
                      setVisibleMenu("");
                    }
                  }}
                  className={visibleMenu && "item-category"}
                >
                  {t(item.name)}
                </div>
                {item.id !== "H" && (
                  <DropdownGameProvider
                    key={`component-cate-dropdown-${index}`}
                    // idCategoryGame={"LC"}
                    // visible={true}
                    idCategoryGame={item.id}
                    visible={visibleMenu === item.id}
                    handleRedirectSubGame={handleRedirectSubGame}
                  />
                )}
              </div>
            ))}
          </div>
          <div css={HeaderStyle.menuList}>
            {headerMenu.map((item: HeaderMenuStyle) => {
              if (!isShow(isAuthenticated, item.label))
                return <div key={item.label} />;
              return (
                <div
                  key={item.label}
                  css={HeaderStyle.menuItem(colorText)}
                  onClick={() => handleActionMenu(item)}
                >
                  <div css={HeaderStyle.menuItemIcon}>
                    <item.icon />
                  </div>
                  <span className={visibleMenu && "item-category"}>
                    {t(item.label)}
                  </span>
                </div>
              );
            })}
          </div>

          <div css={HeaderStyle.rows}>
            <ButtonTelegram />

            {isLoading ? (
              <Loading height={40} center width={140} fontSize={24} />
            ) : (
              <>
                {isAuthenticated ? (
                  <HeaderAuth visibleMenu={visibleMenu} colorText={colorText} />
                ) : (
                  <HeaderUnAuth />
                )}
              </>
            )}
            <LanguageSelect />
          </div>
        </div>
      </div>
    </div>
  );
};
