import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { DepositOfflineSubmissionFormNews } from "types/deposit";

import {
  DepositFormSettingResponse,
  DepositOfflineSubmissionForm,
  DepositOnlineSubmissionForm,
  DepositOnlineSubmissionFormNew,
  DepositResponse,
  DepositSubmissionFormType,
} from "./type";

export const DepositApi = {
  getDepositForm: (username: string) =>
    axiosRequest.post<DepositFormSettingResponse>(
      "",
      payloadHelper("DepositForm", username, {
        username,
      })
    ),
  OnlineDepositSubmission: (
    typename: string,
    payload: DepositOnlineSubmissionForm
  ) =>
    axiosRequest.post<any>(
      "/",
      payloadHelper("GatewayDeposit", typename, payload)
    ),
  OnlineDepositSubmissionNew: (
    typename: string,
    payload: DepositOnlineSubmissionFormNew
  ) =>
    axiosRequest.post<any>(
      "/",
      payloadHelper("GatewayDeposit", typename, payload)
    ),
  depositSubmission: (
    typename: string,
    payload:
      | DepositOnlineSubmissionForm
      | DepositOnlineSubmissionFormNew
      | DepositOfflineSubmissionForm
      | DepositSubmissionFormType
  ) =>
    axiosRequest.post<DepositResponse>(
      "/",
      payloadHelper("Deposit", typename, payload)
    ),
  depositSubmissionOffline: (
    typename: string,
    payload: DepositOfflineSubmissionFormNews
  ) =>
    axiosRequest.post<DepositResponse>(
      "/",
      payloadHelper("Deposit", typename, payload)
    ),
};
