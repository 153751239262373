const isWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  // Check for Android WebView (presence of "wv")
  const isAndroidWebView = /Android/.test(userAgent) && /wv/.test(userAgent);
  // Check for iOS WebView
  const isIOSWebView =
    /iPhone|iPod|iPad/.test(userAgent) && /WebView/.test(userAgent);

  return isAndroidWebView || isIOSWebView;
};

const isIOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /iPhone|iPod|iPad/.test(userAgent);
};

export const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isMobile = /android|iPad|iPhone|iPod/.test(userAgent);
  const isTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;
  const isSmallScreen = window.innerWidth <= 800 && window.innerHeight <= 600;
  console.log("userAgent", userAgent);
  if (isMobile || isTouch || isSmallScreen) {
    if (isWebView()) {
      return 2;
    }
    return isIOS() ? 4 : 3;
  }
  return 1;
};
